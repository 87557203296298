import React, { ReactNode } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioMaterial, { RadioProps as RadioPropsMaterial } from '@mui/material/Radio';
import classNames from 'classnames/bind';

import styles from './Radio.module.css';

const cn = classNames.bind(styles);

export type RadioProps = {
  className?: string;
  radioClassName?: string;
  label?: ReactNode;
  checked?: boolean;
  variant?: 'outlined' | 'standard';
  labelClassName?: string;
  checkedLabelColored?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  subLabel?: string;
} & Pick<
  RadioPropsMaterial,
  'onChange' | 'required' | 'disabled' | 'value' | 'name' | 'disableRipple' | 'focusVisibleClassName' | 'autoFocus'
>;

export function Radio({
  className,
  radioClassName,
  disabled = false,
  checked = false,
  label,
  name = '',
  onChange,
  required = false,
  value = false,
  checkedLabelColored = false,
  disableRipple = false,
  variant = 'standard',
  labelClassName,
  labelPlacement,
  focusVisibleClassName,
  subLabel,
  autoFocus,
}: RadioProps) {
  const radio = (
    <RadioMaterial
      disabled={disabled}
      required={required}
      autoFocus={autoFocus}
      disableRipple={disableRipple}
      className={cn('radio__input', radioClassName, {
        'radio__input--disabled': disabled,
        'radio__input--checked': checked,
      })}
      focusVisibleClassName={cn('radio__focus-visible', focusVisibleClassName)}
      value={value}
      onChange={onChange}
      color="default"
      {...(name && { name })}
    />
  );

  return (
    <FormControl variant={variant} required={required} className={cn('radio', className)}>
      <FormControlLabel
        disabled={disabled}
        control={radio}
        className={cn('radio__label', labelClassName, {
          'radio__label--disabled': disabled,
          'radio__label--checked-colored': checked && checkedLabelColored,
          'radio__label--with-sub-label': !!subLabel,
        })}
        labelPlacement={labelPlacement}
        label={
          <>
            {label}
            {subLabel && <div className={cn('radio__sub-label')}>{subLabel}</div>}
          </>
        }
      />
    </FormControl>
  );
}
