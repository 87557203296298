import React from 'react';

import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { PATHS } from '@common/routes';
import { Alert } from '@components/Alert';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { MailboxTypeEnum } from '@modules/Messages/constants';

import { ConversationsList } from './components/ConversationsList';
import { LinkedFamilyMembers } from './components/LinkedFamilyMembers';
import { MailboxTypeMenu } from './components/MailboxTypeMenu';
import { MessageBox } from './components/MessageBox';
import { Pagination } from './components/Pagination';
import { Search } from './components/Search';
import { locale } from './locale';

import styles from './Messages.module.css';

const cn = classNames.bind(styles);

export const Messages = () => {
  const { conversationId, mailboxType, memberId } = useQueryParamsObject();
  const { isDesktopView } = useScreenSize();

  return (
    <div className={cn('messages')}>
      <div className={cn('messages__title-container')}>
        <Typography className={cn('messages__title')} variant="h1">
          {locale.header}
        </Typography>
        <Button
          component={RouterLink}
          to={generatePathWithQuery(PATHS.MESSAGES.EDIT, { memberId, mailboxType })}
          variant="contained"
          color="primary"
          high={!isDesktopView ? 'low' : 'medium'}
          startIcon="envelope"
          iconClass={cn('messages__new-message--icon')}
        >
          {locale.new_message}
        </Button>
      </div>
      {!(!isDesktopView && conversationId) && <LinkedFamilyMembers className={cn('messages__linked-family-members')} />}
      <div className={cn('messages__layout-container')}>
        {!(!isDesktopView && conversationId) && (
          <div className={cn('messages__list-container')}>
            <MailboxTypeMenu className={cn('messages__type-menu')} />
            <Search className={cn('messages__search')} />
            {mailboxType === MailboxTypeEnum.Archive && (
              <Alert type="warning" noIcon>
                {locale.archive_warning}
              </Alert>
            )}
            <ConversationsList className={cn('messages__list')} />
            <Pagination className={cn('messages__pagination')} />
          </div>
        )}
        {!(!isDesktopView && !conversationId) && (
          <div className={cn('messages__message-box-container')}>
            <MessageBox />
          </div>
        )}
      </div>
    </div>
  );
};
