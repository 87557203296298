import React, { useCallback, useEffect, useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import classNames from 'classnames/bind';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { Button } from '@components/Button';
import { FaIcon } from '@components/FaIcon';
import { Spinner } from '@components/Spinner';
import { SrA11YAnnouncer } from '@components/SrA11YAnnouncer';

import { locale } from './locale';
import { ModalProps, OnCloseType } from './types';

import styles from './Modal.module.css';

const cn = classNames.bind(styles);

export const Modal = ({
  actions,
  children,
  className,
  paperClassName,
  crossbuttonClassName,
  iconClassName,
  modalContentClassName,
  modalActionsClassName,
  disableEscapeKeyDown = false,
  onClose,
  open,
  title,
  withCloseButton,
  closeButtonClassName,
  fullScreen,
  ignoreFullScreen,
  showCrossButton = true,
  ignoreBackdropClick = false,
  withoutBorders = false,
  role,
  ariaLabelledby,
  ariaDescribedby,
  titleId,
  id,
  hasLoadingIndicator,
  transitionDuration,
  size,
  loading,
  spinnerClassName,
  fixedWidth,
}: ModalProps) => {
  const { isMobileView } = useScreenSize();
  const isFullScreen = !ignoreFullScreen && (fullScreen || isMobileView);
  const crossButtonRef = useRef<HTMLButtonElement>(null);
  const handleClose = useCallback<OnCloseType>(
    (e, reason) => {
      if (reason === 'backdropClick' && ignoreBackdropClick) {
        return;
      }
      onClose?.(e, reason);
    },
    [ignoreBackdropClick, onClose],
  );

  useEffect(() => {
    if (!hasLoadingIndicator && crossButtonRef.current) {
      crossButtonRef.current.focus();
    }
  }, [hasLoadingIndicator]);

  return (
    <Dialog
      className={cn('modal', className)}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={isFullScreen}
      fullWidth
      onClose={handleClose}
      open={open}
      scroll="paper"
      classes={{
        paperFullWidth: cn('modal__paper', paperClassName, {
          [`modal__paper--${size}`]: !!size,
          'fixed-width': fixedWidth,
        }),
      }}
      aria-labelledby={titleId || ariaLabelledby}
      aria-describedby={ariaDescribedby}
      id={id}
      transitionDuration={transitionDuration}
    >
      {showCrossButton && (
        <Button
          className={cn('modal__close-button', crossbuttonClassName)}
          disableRipple
          noHover
          onClick={onClose}
          variant="text"
          aria-label={locale.closeLabel}
          title={locale.closeLabel}
          ref={crossButtonRef}
          autoFocus={hasLoadingIndicator === undefined}
        >
          <FaIcon className={cn(iconClassName)} iconName="xmark-large" size="small" />
        </Button>
      )}
      {loading && (
        <div className={cn('spinner-container', spinnerClassName)}>
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          {title && (
            <div
              className={cn('modal__title', {
                'modal__title--no-border': withoutBorders,
              })}
              id={titleId}
            >
              {title}
            </div>
          )}
          <div className={cn('modal__content', modalContentClassName)} role={role}>
            {children}
          </div>
          {(actions || withCloseButton) && (
            <div
              className={cn('modal__actions', modalActionsClassName, {
                'modal__actions--no-border': withoutBorders,
              })}
            >
              {actions}
              {withCloseButton && (
                <Button onClick={onClose} className={cn('modal__close', closeButtonClassName)} variant="contained">
                  {locale.close}
                </Button>
              )}
            </div>
          )}
        </>
      )}
      <SrA11YAnnouncer message={hasLoadingIndicator ? locale.busyIndicator : ''} />
    </Dialog>
  );
};
