import React, { useCallback, useState } from 'react';

import { Typography } from '@mui/material';
import classNames from 'classnames/bind';

import { getLocale } from '@common/utils/locale';
import { FaIcon, IconColors } from '@components/FaIcon';
import { TextFieldWithControlProps, TextFieldWithControl, FieldValues } from '@components/Form';
import { InputAdornment } from '@components/TextField';

import { locale } from './locale';

import styles from './PasswordField.module.css';

const cn = classNames.bind(styles);

export function PasswordField<FormValues extends FieldValues>({
  control,
  className,
  disabled,
  label,
  name,
  placeholder,
  required,
  validate,
  fullWidth,
  autoComplete,
  deps,
  mask,
  rows,
  id,
}: TextFieldWithControlProps<FormValues>) {
  const [isPassword, setIsPassword] = useState(true);

  const handleTogglePassword = useCallback(() => {
    setIsPassword((isPassword) => !isPassword);
  }, []);

  return (
    <TextFieldWithControl
      className={cn('password-field', className)}
      control={control}
      disabled={disabled}
      label={label}
      name={name}
      placeholder={placeholder}
      validate={validate}
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      deps={deps}
      type={isPassword ? 'password' : 'text'}
      rows={rows}
      id={id}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <button
              type="button"
              className={cn('password-field__toggle-visibility')}
              onClick={handleTogglePassword}
              tabIndex={0}
              aria-label={getLocale(locale[isPassword ? 'show_password_label' : 'hide_password_label'], {
                label: label as string,
              })}
              aria-controls={id}
            >
              <FaIcon iconName={isPassword ? 'eye' : 'eye-slash'} iconPrefix="far" color={IconColors.Gray} />
              <Typography variant="button" className={cn('password-field__toggle-visibility-text')}>
                {locale[isPassword ? 'show_password' : 'hide_password']}
              </Typography>
            </button>
            <div aria-live="polite" className="sr-only">
              {getLocale(locale[isPassword ? 'hidden' : 'shown'], { label: label as string })}
            </div>
          </InputAdornment>
        ),
      }}
      required={required}
      mask={mask}
    />
  );
}
