import React from 'react';

import classNames from 'classnames';

import { DropDownMenuItem } from '@components/DropDownMenuItem';
import { ShowCategory } from '@modules/categories';
import { useFlippers } from '@modules/flippers';

import { MenuItemType } from '../../types';

export interface ProfileMenuProps {
  menuItems: Array<MenuItemType>;
  menuItemClassName?: string;
  menuItemContentClassName?: string;
  groupedMenuItemClassName?: string;
  onClose?: VoidFunction;
}

export function ProfileMenu({
  menuItems,
  menuItemClassName,
  menuItemContentClassName,
  groupedMenuItemClassName = '',
  onClose,
}: ProfileMenuProps) {
  const getFlipperValue = useFlippers();

  const handleItemClick = (action: MenuItemType['action']) => {
    action?.();
    onClose?.();
  };

  const renderMenuItem = (menuItem: MenuItemType, index: number, array: Array<MenuItemType>) => {
    const key = menuItem.label || menuItem.to || index;

    if (menuItem.hideFlipper && getFlipperValue(menuItem.hideFlipper)) {
      return null;
    }

    if (menuItem.showFlipper && !getFlipperValue(menuItem.showFlipper)) {
      return null;
    }

    const autoFocus = index === 0;
    const className = classNames(menuItemClassName, menuItem.menuItemClassName, {
      [groupedMenuItemClassName]: menuItem.grouped,
    });
    const noDivider = menuItem.grouped && Boolean(array[index + 1]?.grouped);
    const handleClick = menuItem.to ? undefined : () => handleItemClick(menuItem.action);

    return (
      <ShowCategory key={key} category={menuItem.label || ''}>
        <DropDownMenuItem
          autoFocus={autoFocus}
          className={className}
          leftIconName={menuItem.iconName}
          leftIconPrefix={menuItem.iconPrefix}
          badge={menuItem.badge}
          noHover={menuItem.noHover}
          divider={!noDivider}
          onClick={handleClick}
          to={menuItem.to}
          replace={menuItem.replace}
          contentClassName={menuItemContentClassName}
        >
          {menuItem.component || menuItem.label}
        </DropDownMenuItem>
      </ShowCategory>
    );
  };

  return <>{menuItems.map(renderMenuItem)}</>;
}
