import React from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as AppStore } from '@assets/app_store.svg';
import { ReactComponent as GooglePlay } from '@assets/play.svg';
import { APP_STORE_APP, PLAY_MARKET_APP } from '@common/constants/apps';
import { Link } from '@components/Link';
import { Typography } from '@components/Typography';

import { locale } from '../../locale';

import styles from './ApplicationsButtons.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};

export const ApplicationsButtons = ({ className }: Props) => (
  <footer className={cn('application-buttons', className)}>
    <Typography component="p" variant="helper" id="main_common_label">{locale.download_app}</Typography>
    <p className="sr-only" id="app_label">
      Get it on App Store.
    </p>
    <p className="sr-only" id="google_label">
      Get it on Google Play.
    </p>
    <div className={cn('application-buttons__button-container')}>
      <Link ariaLabelledBy="main_common_label app_label" to={APP_STORE_APP} className={cn('application-buttons__link')}>
        <AppStore role="img" title="App Store" className={cn('application-buttons__button')} />
      </Link>
      <Link
        ariaLabelledBy="main_common_label google_label"
        to={PLAY_MARKET_APP}
        className={cn('application-buttons__link')}
      >
        <GooglePlay role="img" title="Google Play" className={cn('application-buttons__button')} />
      </Link>
    </div>
  </footer>
);
