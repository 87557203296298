import React, { useMemo } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';

import { APP_STORE_APP, PLAY_MARKET_APP } from '@common/constants/apps';
import { useScreenSize } from '@common/hooks/useScreenSize';
import { FaIcon, IconColors } from '@components/FaIcon';
import { Link } from '@components/Link';
import { Typography } from '@components/Typography';

import { locale } from '../../locale';
import { ApplicationsButtons } from '../ApplicationsButtons';

import styles from './Description.module.css';

const cn = classNames.bind(styles);

export const Description = () => {
  const { isMobileView } = useScreenSize();

  const renderInfo = useMemo(
    () => (
      <ul className={cn('description__use-to-items')}>
        {locale.description.use_to_items.map((item) => (
          <li key={item.title} className={cn('list__item')}>
            <FaIcon
              iconName={item.icon as IconName}
              iconPrefix="fad"
              className={cn('list__icons')}
              color={IconColors.Purple}
              size="large"
            />
            <div>
              <Typography variant="h4" component="span" className={cn('list__item--title')}>
                {item.title}
              </Typography>
              <Typography variant="h4" component="span">
                {item.desc}
              </Typography>
            </div>
          </li>
        ))}
        <li className={cn('list__item')}>
          <FaIcon
            iconName={locale.description.download_item.icon as IconName}
            iconPrefix="fad"
            className={cn('list__icons')}
            color={IconColors.Purple}
            size="large"
          />
          <div>
            <Typography variant="h4" component="span">
              {locale.description.download_item.desc}
            </Typography>
            <Link
              ariaLabelledBy="main_common_label app_label"
              to={APP_STORE_APP}
              className={cn('application-buttons__link')}
            >
              {locale.description.download_item.ios}
            </Link>
            <Typography variant="h4" component="span">
              {locale.description.download_item.desc_2}
            </Typography>

            <Link
              ariaLabelledBy="main_common_label google_label"
              to={PLAY_MARKET_APP}
              className={cn('application-buttons__link')}
            >
              {locale.description.download_item.android}
            </Link>
          </div>
        </li>
      </ul>
    ),
    [],
  );

  const renderMobileInfo = useMemo(
    () => (
      <>
        <div
          className={cn('description__need_help')}
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 8,
            display: 'flex',
          }}
        >
          <Typography component="p" variant="body2-bold">
            {locale.description.need_help_en}
          </Typography>
          <Typography component="p" variant="body2-bold">
            {locale.description.need_help_es}
          </Typography>
        </div>

        <Typography component="p" variant="helper" className={cn('description__additional')}>
          {locale.description.additional_mobile}
        </Typography>
        <ApplicationsButtons />
      </>
    ),
    [],
  );

  return (
    <div className={cn('description')}>
      <div className={cn('description__information')}>
        {!isMobileView && (
          <div className={cn('description__list-wrapper')}>
            <Typography className={cn('description__title')} variant="h2" component="p">
              {locale.description.title}
            </Typography>
            {renderInfo}

            <Typography component="p" variant="helper" className={cn('description__additional')}>
              {locale.description.additional}
            </Typography>

            <div
              className={cn('description__need_help')}
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                gap: 16,
                display: 'flex',
              }}
            >
              <Typography component="p" variant="body2-bold">
                {locale.description.need_help_en}
              </Typography>
              <Typography component="p" variant="body2-bold">
                {locale.description.need_help_es}
              </Typography>
            </div>
          </div>
        )}
        {isMobileView && renderMobileInfo}
      </div>
    </div>
  );
};
