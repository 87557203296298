import React from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';

import { Button } from '@components/Button';
import { IconType, SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import { InternalErrorType } from './constants';
import { locale } from './locale';

import styles from './InternalError.module.css';

const cn = classNames.bind(styles);

type Action = {
  icon: IconName;
  action: () => void;
  title: string;
};

type Props = {
  type?: InternalErrorType;
  actions?: Array<Action>;
};

export function InternalError({ type = InternalErrorType.ServiceError, actions }: Props) {
  return (
    <div
      className={cn('root', {
        [`root--${type}`]: type,
      })}
    >
      <nav>
        <SvgIcon className={cn('logo')} viewBox="0 0 305 99" icon={'mainLogo' as IconType} />
      </nav>
      <div>
        <Typography
          variant="h2"
          component="h1"
          className={cn({
            [`heading--${type}`]: type,
          })}
        >
          {locale[type].errorCode} {locale[type].error}
        </Typography>
        <Typography
          className={cn('heading', {
            [`heading--${type}`]: type,
          })}
          variant="internal-error"
          component="h2"
        >
          {locale[type].heading}
        </Typography>
        <Typography
          className={cn('sub-text', {
            [`sub-text--${type}`]: type,
          })}
        >
          {locale[type].subText}
        </Typography>

        <Typography
          className={cn('sub-heading', {
            [`sub-heading--${type}`]: type,
          })}
          variant="h2"
        >
          {locale[type].subHeading}
        </Typography>
        {actions && (
          <div className={cn('button-container')}>
            {actions.map(({ icon, title, action }) => (
              <Button
                key={title}
                onClick={action}
                className={cn('button', {
                  [`button--${type}`]: type,
                })}
                variant="text"
                startIcon={icon}
              >
                {title}
              </Button>
            ))}
          </div>
        )}
      </div>
      {!!locale[type]?.gratitude && (
        <Typography
          className={cn('gratitude', {
            [`gratitude--${type}`]: type,
          })}
        >
          {locale[type]?.gratitude}
        </Typography>
      )}
    </div>
  );
}
