import { getImpersonation } from '@common/utils';

import { GtmCommandsEnum, GtmEventsEnum, userOnlyEvents } from './constants';
import { GtmSetParams, GtmEventParamsMap } from './types';

const userOnlyEvent = (event: GtmEventsEnum) => userOnlyEvents?.includes(event);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const gtag = (command: GtmCommandsEnum, ...args: unknown[]) => null;

export const gtmConfig = (...args: unknown[]) => gtag(GtmCommandsEnum.config, ...args);

export const gtmSet = (params: Partial<GtmSetParams>) => gtag(GtmCommandsEnum.set, params);

export const gtmEvent = <E extends GtmEventsEnum>(
  event: E,
  ...params: GtmEventParamsMap[E] extends undefined ? [] : [GtmEventParamsMap[E]]
) => {
  const isImpersonation = !!getImpersonation();
  if (isImpersonation && !userOnlyEvent(event)) {
    return null;
  }
  gtag(GtmCommandsEnum.event, event, ...params);
};

type SetUserPropertiesUtilType = {
  wellnessId?: string;
  bornOn?: string;
  gender?: string;
  employerId?: number;
  employerName?: string;
};

export const gtmSetUserProperties = ({
  wellnessId,
  bornOn,
  gender,
  employerId,
  employerName,
}: SetUserPropertiesUtilType) => {
  gtmSet({
    user_properties: {
      user_id: wellnessId,
      wellness_id: wellnessId,
      born_on: bornOn,
      gender: wellnessId ? gender || 'unknown' : undefined,
      employerId: wellnessId ? employerId : '',
      employerName: wellnessId ? employerName : '',
    },
  });
};
