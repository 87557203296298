import React, { MouseEventHandler, ReactNode, useCallback, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIgnoreEffectDeps } from '@common/hooks';
import { useScreenSize } from '@common/hooks/useScreenSize';
import { getInitialTabByPath, getInitialTabByQuery, getTabById, TabItemProp } from '@common/utils/menuUtils';
import { AppBar } from '@components/AppBar';
import { Button } from '@components/Button';
import { Menu } from '@components/Menu';
import { SvgIcon } from '@components/SvgIcon';
import { NextTab, Tabs } from '@components/Tabs';

import { MobileSubMenu } from './components/MobileSubMenu';
import { MenuItemType, SubMenu } from './components/SubMenu';
import { TabProps } from './menu-inside-module-types';

import styles from './MenuInsideModule.module.css';

const cn = classNames.bind(styles);

export interface MenuInsideModuleProps {
  visibleMenuItems: Array<TabProps>;
  className?: string;
  tabClassName?: string;
  onSubMenuItemClick?: (menuItem: MenuItemType, to?: string) => void;
  onMenuItemClick?: (menuItem: TabProps, to?: string) => void;
  selectedComponent?: ReactNode;
  onTabChange?: (item: TabProps) => unknown;
  dropdownView?: boolean;
  renderCustomMenuItem?: Nullable<(item: TabProps) => ReactNode>;
  initialCustomTabId?: string | number;
}

const MENU_ID = 'sub-menu';
const TRIGGER_ID = 'sub-button';

export function MenuInsideModule({
  visibleMenuItems,
  className,
  tabClassName,
  onSubMenuItemClick,
  onMenuItemClick,
  selectedComponent,
  onTabChange,
  dropdownView,
  renderCustomMenuItem,
  initialCustomTabId,
}: MenuInsideModuleProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(menuAnchorEl);

  const { isDesktopView } = useScreenSize();

  const location = useLocation();
  const navigate = useNavigate();

  const handleTriggerButtonClick = useCallback<MouseEventHandler>(
    (event) => {
      if (menuAnchorEl !== event.currentTarget) {
        setMenuAnchorEl(event.currentTarget as HTMLElement);
      }
    },
    [menuAnchorEl],
  );

  const handleCloseMenu = useCallback(() => {
    setTimeout(() => {
      menuAnchorEl?.focus();
    });
    setMenuAnchorEl(null);
  }, [menuAnchorEl]);

  const tabId =
    initialCustomTabId ??
    getInitialTabByPath(location.pathname, visibleMenuItems as TabItemProp[]) ??
    getInitialTabByQuery(location.search, visibleMenuItems as TabItemProp[]);
  const [selectedTab, setSelectedTab] = useState(() => tabId);

  useIgnoreEffectDeps(() => {
    if (tabId !== selectedTab) {
      setSelectedTab(tabId);
    }
  }, [location.pathname, location.search, tabId]);

  const selectTabAndNavigate = useCallback(
    (tabId: number, path: string) => {
      if (path) {
        setSelectedTab(tabId);
        navigate(path);
      }
    },
    [navigate],
  );

  const handleTabChange = useCallback(
    (_: unknown, newId: number) => {
      const tabIndex = getTabById(newId, visibleMenuItems as TabItemProp[]);
      const tab = visibleMenuItems[tabIndex];

      onTabChange?.(tab);

      if (tab.to && !tab.subMenuItems) {
        selectTabAndNavigate(newId, tab.to);
      }
    },
    [visibleMenuItems, selectTabAndNavigate, onTabChange],
  );

  const menuItemsWithSubMenu = useMemo(
    () => visibleMenuItems.filter((menuItem) => menuItem.subMenuItems?.length),
    [visibleMenuItems],
  );

  if (isDesktopView && !dropdownView) {
    return (
      <AppBar className={cn('module-menu', className)} component="div">
        <Tabs onChange={handleTabChange} value={selectedTab}>
          {visibleMenuItems.map((tab) => (
            <NextTab
              key={tab.id}
              value={tab.id}
              label={
                <div className={cn('module-menu__label-wrapper')}>
                  <span>{tab.label}</span>
                  {Boolean(tab.subMenuItems?.length) && (
                    <SvgIcon className={cn('module-menu__label-icon')} icon="chevronDown" />
                  )}
                </div>
              }
              onClick={tab.subMenuItems ? handleTriggerButtonClick : undefined}
              selectedBackground={tab.id === selectedTab}
              className={cn('module-menu__tab', tabClassName)}
              tabRootClass={cn('module-menu__tab-root')}
              aria-haspopup={tab.subMenuItems ? 'true' : undefined}
              aria-controls={tab.subMenuItems && open ? MENU_ID : undefined}
              aria-expanded={tab.subMenuItems ? open : undefined}
              disableRipple={false}
            />
          ))}
        </Tabs>

        <Menu
          id={MENU_ID}
          anchorEl={menuAnchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            className: cn('module-menu__content'),
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {menuItemsWithSubMenu &&
            menuItemsWithSubMenu.map((tabWithSubMenu) => (
              <SubMenu
                handleClose={handleCloseMenu}
                onSubMenuItemClick={onSubMenuItemClick}
                key={tabWithSubMenu.id}
                to={tabWithSubMenu.to}
                menuItems={tabWithSubMenu.subMenuItems as Array<MenuItemType>}
              />
            ))}
        </Menu>
      </AppBar>
    );
  }

  return (
    <AppBar className={cn('mobile-module-menu', className)} component="div">
      <Button
        variant="text"
        className={cn('mobile-module-menu__btn', {
          'mobile-module-menu__btn--active': open,
        })}
        disableRipple
        noHover
        id={TRIGGER_ID}
        endIcon="chevron-down"
        iconSize="small"
        color="gray"
        onClick={handleTriggerButtonClick}
        ariaExpanded={open}
        aria-controls={open ? MENU_ID : undefined}
        aria-haspopup
      >
        {selectedComponent}
      </Button>

      <Menu
        id={MENU_ID}
        anchorEl={menuAnchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': TRIGGER_ID,
          className: cn('mobile-module-menu__content'),
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MobileSubMenu
          selectedTab={selectedTab}
          handleClose={handleCloseMenu}
          onSubMenuItemClick={onSubMenuItemClick}
          onMenuItemClick={onMenuItemClick}
          menuItems={visibleMenuItems}
          renderCustomMenuItem={renderCustomMenuItem}
        />
      </Menu>
    </AppBar>
  );
}
