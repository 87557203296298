import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { StatusEnum } from '@common/constants';
import { PATHS } from '@common/routes';
import { Button } from '@components/Button';
import { FieldRow } from '@components/FieldRow';
import { TextFieldWithControl, useForm } from '@components/Form';
import { ColorEnum, Link } from '@components/Link';
import { PasswordField } from '@components/PasswordField';
import { Typography } from '@components/Typography';
import { GtmClickTypesEnum, gtmEvent, GtmEventsEnum } from '@modules/gtm';

import { useLoginRedirect, useMobileLoginRedirect } from '../../hooks';
import { locale } from '../../locale';
import { getLoginStatus, login } from '../../store';
import { LoginPayload } from '../../store/types';
import { Errors } from '../Errors';

import styles from './Form.module.css';

enum FieldNames {
  username = 'username',
  password = 'password',
}

const cn = classNames.bind(styles);

const gtmNewUserClick = () => gtmEvent(GtmEventsEnum.click, { type: GtmClickTypesEnum.loginNewUserLink });

export const Form = () => {
  const { handleSubmit, control, formState } = useForm<LoginPayload>();
  const dispatch = useDispatch();
  const status = useSelector(getLoginStatus);
  const { isDirty } = formState;

  const onSubmit = useCallback(
    (data: LoginPayload) => {
      dispatch(login(data));
    },
    [dispatch],
  );

  useLoginRedirect();
  useMobileLoginRedirect();

  return (
    <main className={cn('form')}>
      <div className={cn('form__container')}>
        <div className={cn('form__inner-container')}>
          <Errors />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography className={cn('form__title')} variant="hxl" component="h2">
              {locale.form.sign_in}
            </Typography>
            <FieldRow size="xsmall">
              <TextFieldWithControl
                control={control}
                id="sign_in__username"
                className={cn('form__field')}
                disabled={status === StatusEnum.Pending}
                label={locale.form.username}
                name={FieldNames.username}
                placeholder={locale.form.placeholder_login}
                autoComplete="username"
                required
              />
            </FieldRow>
            <FieldRow className={cn('form__field-row')}>
              <PasswordField
                className={cn('form__field')}
                control={control}
                disabled={status === StatusEnum.Pending}
                label={locale.form.password}
                name={FieldNames.password}
                placeholder={locale.form.placeholder_password}
                autoComplete="current-password"
                id="sign_in__password"
                required
              />
              <div className={cn('form__action-container')}>
                <Link to={PATHS.PASSWORD_RECOVERY.ROOT} color={ColorEnum.GRAY_20}>
                  {locale.form.forgot_password}
                </Link>
              </div>
            </FieldRow>
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              className={cn('form__button')}
              disabled={!isDirty}
            >
              {locale.form.sign_in}
            </Button>

            <Button
              variant="outlined"
              component={RouterLink}
              onClick={gtmNewUserClick}
              to={PATHS.REGISTER.ROOT}
              fullWidth
            >
              {locale.form.register}
            </Button>
          </form>
        </div>
      </div>
    </main>
  );
};
