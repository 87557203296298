import React from 'react';

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';

import { FaIcon, IconColors } from '@components/FaIcon';
import { SvgIcon, IconType } from '@components/SvgIcon';

import styles from './ActionItemInner.module.css';

const cn = classNames.bind(styles);

type Props = {
  title: string;
  svgIcon?: IconType;
  faIconName?: IconName;
  faIconPrefix?: IconPrefix;
};

export const ActionItemInner = ({ title, svgIcon, faIconName, faIconPrefix = 'far' }: Props) => (
  <span className={cn('root')}>
    {faIconName && (
      <FaIcon className={cn('icon')} iconName={faIconName} iconPrefix={faIconPrefix} color={IconColors.Gray} />
    )}
    {svgIcon && <SvgIcon className={cn('icon')} icon={svgIcon} />}
    {title}
  </span>
);
