import React from 'react';

import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';

import styles from './FaIcon.module.css';

const cn = classNames.bind(styles);

export enum IconColors {
  Green = 'green',
  GreenInverted = 'green-inverted',
  Purple = 'purple',
  PurpleInverted = 'purple-inverted',
  Blue = 'blue',
  Magenta = 'magenta',
  Teal = 'teal',
  DarkGreen = 'dark-green',
  Gray = 'gray',
  Red = 'red',
}

type DuoToneColorsType =
  | IconColors.Green
  | IconColors.Purple
  | IconColors.Blue
  | IconColors.Teal
  | IconColors.PurpleInverted
  | IconColors.GreenInverted;
type RegularColorsType =
  | IconColors.Green
  | IconColors.Purple
  | IconColors.Blue
  | IconColors.Magenta
  | IconColors.DarkGreen
  | IconColors.Gray
  | IconColors.Red
  | IconColors.Teal;
type SolidColorsType = IconColors.Gray | IconColors.Green | IconColors.Magenta | IconColors.Teal;

type faIconProps = {
  iconName: IconName;
  className?: string;
  color?: DuoToneColorsType | RegularColorsType | SolidColorsType;
  iconPrefix?: IconPrefix;
  size?: 'small' | 'medium' | 'large' | 'xl';
  lockedColor?: boolean;
};

export const FaIcon = ({
  iconName,
  className,
  color,
  iconPrefix = 'far',
  size = 'medium',
  lockedColor,
}: faIconProps) => (
  <FontAwesomeIcon
    icon={[iconPrefix, iconName]}
    className={cn(
      className,
      `${color}--${iconPrefix}`,
      `${color}`,
      `icon--${size}`,
      `icon`,
      lockedColor ? `${color}--important` : null,
    )}
  />
);
