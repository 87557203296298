import React from 'react';

import classNames from 'classnames/bind';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { SideBarWidget } from '@components/SideBarWidget';
import { SvgIcon } from '@components/SvgIcon';

import { Description } from './components/Description';
import { Form } from './components/Form';

import styles from './Login.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};

export const Login = ({ className }: Props) => {
  const { isDesktopView } = useScreenSize();

  return (
    <>
      <header className={cn('login__header')}>
        <div className={cn('login__header--left')}>
          <h1 className={cn('login__logo-container')}>
            <SvgIcon icon="mainLogo" className={cn('login__logo')} viewBox="0 0 305 99" />
            <span className="sr-only">Marathon Health Portal. </span>
          </h1>
        </div>
        {isDesktopView && <div className={cn('login__header--right')} />}
      </header>
      <SideBarWidget className={cn('login', className)}>
        {isDesktopView && <Description />}
        <Form />
        {!isDesktopView && <Description />}
      </SideBarWidget>
    </>
  );
};