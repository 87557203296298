import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { PATHS } from '@common/routes';
import { getLocale } from '@common/utils/locale';
import { Button } from '@components/Button';

import { MailboxTypeEnum } from '../../../constants';
import {
  getConversationMessagesSelector,
  draftIdSelector,
  deleteDraft,
  archiveMessage,
  restoreMessage,
} from '../../../store';
import { Actions } from '../constants';
import { locale } from '../locale';

import styles from '../MessageBox.module.css';

const cn = classNames.bind(styles);

const MESSAGE_ACTIONS_MAP = {
  [MailboxTypeEnum.Inbox]: [
    { variant: 'outlined', action: Actions.Archive } as const,
    { variant: 'contained', action: Actions.Reply } as const,
  ],
  [MailboxTypeEnum.Sent]: [
    { variant: 'outlined', action: Actions.Archive } as const,
    { variant: 'contained', action: Actions.Reply } as const,
  ],
  [MailboxTypeEnum.Archive]: [{ variant: 'outlined', action: Actions.Restore } as const],
  [MailboxTypeEnum.Draft]: [
    { variant: 'outlined', action: Actions.Delete } as const,
    { variant: 'contained', action: Actions.Edit } as const,
  ],
};

export const MessageActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParamsObject<{
    conversationId: string;
    memberId: string;
    mailboxType: string;
  }>();
  const { conversationId, memberId, ...restParams } = params;
  const conversationMessages = useSelector(getConversationMessagesSelector);
  const draftId = useSelector(draftIdSelector);
  const location = useLocation();
  const { isDesktopView } = useScreenSize();

  const handleGoBack = useCallback(() => {
    navigate(generatePathWithQuery(location.pathname, { memberId, ...restParams }));
  }, [navigate, location.pathname, memberId, restParams]);

  const handleReply = useCallback(() => {
    navigate(
      generatePathWithQuery(PATHS.MESSAGES.EDIT, {
        ...params,
        reply: 1,
      }),
    );
  }, [params, navigate]);

  const handleArchive = useCallback(() => {
    dispatch(archiveMessage({ conversationId, memberId }));
  }, [conversationId, dispatch, memberId]);

  const handleRestore = useCallback(() => {
    dispatch(restoreMessage({ conversationId, memberId }));
  }, [conversationId, dispatch, memberId]);

  const handleDelete = useCallback(() => {
    if (draftId) {
      dispatch(deleteDraft({ messageId: draftId, memberId }));
    }
  }, [dispatch, draftId, memberId]);

  const handleEdit = useCallback(() => {
    navigate(
      generatePathWithQuery(PATHS.MESSAGES.EDIT, {
        ...params,
        draft: 1,
        reply: conversationMessages && conversationMessages.length > 1 ? 1 : 0,
      }),
    );
  }, [params, conversationMessages, navigate]);

  const ACTIONS = {
    [Actions.Archive]: handleArchive,
    [Actions.Delete]: handleDelete,
    [Actions.Reply]: handleReply,
    [Actions.Edit]: handleEdit,
    [Actions.Restore]: handleRestore,
  };

  return (
    <div className={cn('message-box__actions')}>
      {!isDesktopView && (
        <Button
          onClick={handleGoBack}
          startIcon="chevron-left"
          noHover
          className={cn('message-box__back-button')}
          color="gray"
        >
          {getLocale(locale.back_button_title, {
            mailboxType: locale.mailbox_types[params.mailboxType as MailboxTypeEnum],
          })}
        </Button>
      )}
      <div className={cn('message-box__message-action-container')}>
        {MESSAGE_ACTIONS_MAP[params?.mailboxType as MailboxTypeEnum]?.map(({ action, variant }) => (
          <Button
            onClick={ACTIONS[action]}
            variant={variant}
            color="secondary"
            className={cn('message-box__action-button')}
            key={action}
            high={!isDesktopView ? 'low' : 'medium'}
          >
            {locale.actions[action]}
          </Button>
        ))}
      </div>
    </div>
  );
};
