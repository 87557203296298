import React from 'react';

import classNames from 'classnames/bind';

import { SizesEnum } from './constants';

import styles from './NumberCircle.module.css';

type NumberCircleProps = {
  count: number;
  size?: SizesEnum;
  className?: string;
  color?: 'orange' | 'purple' | 'deep-blue';
  ariaHidden?: boolean;
  ariaLabel?: string;
  variant?: 'contained' | 'outlined';
};

const cn = classNames.bind(styles);

export function NumberCircle({
  count,
  size = SizesEnum.Medium,
  className,
  ariaLabel,
  ariaHidden = undefined,
  color = 'orange',
  variant = 'contained',
}: NumberCircleProps) {
  return (
    <div
      data-testid="number-circle"
      className={cn('number-circle', className, {
        [`number-circle--${size}`]: size,
        [`number-circle--${color}`]: color,
        [`number-circle--${variant}`]: variant,
      })}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
    >
      {count}
    </div>
  );
}
