import React, { ReactNode } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import AlertMaterial, { AlertProps as AlertMaterialProps } from '@mui/material/Alert';
import AlertTitleMaterial from '@mui/material/AlertTitle';
import classNames from 'classnames/bind';

import { FaIcon } from '@components/FaIcon';

import styles from './Alert.module.css';

export type AlertProps = {
  type: 'success' | 'error' | 'info' | 'warning' | 'impersonate' | 'alert';
  title?: string;
  action?: ReactNode;
  titleClassName?: string;
  messageClassName?: string;
  bodyClassName?: string;
  actionClassName?: string;
  iconClassName?: string;
  noIcon?: boolean;
  size?: 'small' | 'medium';
  icon?: ReactNode;
} & Pick<AlertMaterialProps, 'children' | 'variant' | 'className'>;

const cn = classNames.bind(styles);

export const ALERT_ICON_MAP : { [key: string]: IconName }   = {
    alert: 'circle-exclamation',
    success: 'circle-check',
    error: 'circle-exclamation',
    info: 'circle-exclamation',
    warning: 'circle-exclamation',
    impersonate: 'circle-user',
};

export function Alert({
  title,
  action,
  children,
  className,
  variant = 'outlined',
  type,
  icon,
  titleClassName,
  messageClassName,
  bodyClassName,
  actionClassName,
  iconClassName,
  noIcon,
  size,
}: AlertProps) {
  const ICON = icon || (
      <FaIcon className={cn('alert__icon', `alert__icon--${type}`, {
        'alert__icon--title': title
      })} iconPrefix="far" iconName={ALERT_ICON_MAP[type]}/> 
  );

  return (
    <AlertMaterial
      className={cn('alert', `alert--${type}`, className, {
        [`alert--${size}`]: size,
      })}
      icon={noIcon ? false : ICON}
      variant={variant}
      classes={{
        message: cn('alert__message', messageClassName, {
          [`alert__message--${size}`]: size,
        }),
        icon: cn(iconClassName, 'alert__icon', {
          [`alert__icon--${type}`]: type && !icon,
          [`alert__icon--${size}`]: size,
        }),
      }}
    >
      <div className={cn('alert__body', bodyClassName)}>
        <div>
          {!!title && <AlertTitleMaterial className={cn('alert__title', titleClassName)}>{title}</AlertTitleMaterial>}

          {children}
        </div>

        {!!action && <div className={actionClassName}>{action}</div>}
      </div>
    </AlertMaterial>
  );
}
