import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';
import { SnackbarProvider as Provider } from 'notistack';

import { FaIcon } from '@components/FaIcon';

import { CloseButton } from '../CloseButton';

import styles from './SnackbarProvider.module.css';

const cn = classNames.bind(styles);

type SnackbarProviderProps = {
  children: ReactNode;
};

const HIDE_DURATION = 10000;

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  return (
    <Provider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={cn('snackbar')}
      classes={{
        variantSuccess: cn('snackbar--success'),
        variantError: cn('snackbar--error'),
        variantWarning: cn('snackbar--warning'),
        variantInfo: cn('snackbar--info'),
      }}
      iconVariant={{
        success: <FaIcon className={cn('snackbar__icon', 'snackbar__icon--success')} iconName="circle-check" iconPrefix="far"/>,
        error: <FaIcon className={cn('snackbar__icon', 'snackbar__icon--error')} iconName="circle-exclamation" iconPrefix="far"/>,
        warning: <FaIcon className={cn('snackbar__icon', 'snackbar__icon--warning')} iconName="circle-exclamation" iconPrefix="far"/>,
        info: <FaIcon className={cn('snackbar__icon', 'snackbar__icon--info')} iconName="circle-exclamation" iconPrefix="far"/>,
      }}
      action={CloseButton}
      autoHideDuration={HIDE_DURATION}
    >
      {children}
    </Provider>
  );
}
