import React, { ReactNode, ReactEventHandler } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';

import { getLocale } from '@common/utils/locale';
import { Button } from '@components/Button';
import { IconColors } from '@components/FaIcon';

import { locale } from '../../locale';

import styles from './PaginationButton.module.css';

const cn = classNames.bind(styles);

type PaginationButtonProps = {
  disabled: boolean;
  selected?: boolean;
  withMargin?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
  children: ReactNode;
  onClick?: ReactEventHandler<Element>;
  ariaLabel?: string;
  page?: Nullable<number>;
  iconColor?: IconColors;
};

export const PaginationButton = ({
  disabled,
  selected,
  children,
  onClick,
  startIcon,
  endIcon,
  withMargin,
  ariaLabel,
  page,
  iconColor,
}: PaginationButtonProps) => {
  const srLabel = !ariaLabel && page ? getLocale(locale.go_to_page, { page }) : ariaLabel;

  return (
    <Button
      className={cn('pagination-button', {
        'pagination-button--disabled': disabled,
        'pagination-button--selected': selected,
        'pagination-button--with-margin': withMargin,
      })}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      iconColor={iconColor || IconColors.Gray}
      noHover={disabled}
      title={srLabel}
      aria-label={srLabel}
    >
      {children}
    </Button>
  );
};
