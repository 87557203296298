import React, { useCallback, useMemo } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@common/routes';
import { InternalError, InternalErrorType } from '@modules/InternalError';

import { locale } from './locale';

import styles from './InternalServerErrorPage.module.css';

const cn = classNames.bind(styles);

export function InternalServerErrorPage() {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goHome = useCallback(() => {
    navigate(ROUTES.MAIN_PAGE);
  }, [navigate]);

  const reloadPage = useCallback(() => {
    navigate(0);
  }, [navigate]);

  const actions = useMemo(
    () => [
      { icon: 'arrow-rotate-right' as IconName, title: locale.refreshPage, action: reloadPage },
      { icon: 'arrow-left' as IconName, title: locale.goBack, action: goBack },
      { icon: 'arrow-left' as IconName, title: locale.goHome, action: goHome },
    ],
    [goBack, goHome, reloadPage],
  );

  return (
    <div className={cn('internal-server-error-page')}>
      <InternalError type={InternalErrorType.ServiceError} actions={actions} />
    </div>
  );
}
