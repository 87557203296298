import React, { ReactNode } from 'react';

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';

import { MenuItem } from '@components/Menu';

import styles from './DropDownMenuItem.module.css';

const cn = classNames.bind(styles);

export interface ProfileMenuItemProps {
  className?: string;
  noHover?: boolean;
  halfPadding?: boolean;
  children: ReactNode;
  badge?: ReactNode;
  leftIconName?: IconName;
  leftIconPrefix?: IconPrefix;
  onClick?: VoidFunction;
  autoFocus?: boolean;
  to?: string;
  replace?: boolean;
  divider?: boolean;
  contentClassName?: string;
}

export function DropDownMenuItem({
  className,
  noHover,
  children,
  halfPadding,
  leftIconName,
  leftIconPrefix,
  badge,
  onClick,
  autoFocus,
  to,
  replace,
  divider,
  contentClassName,
}: ProfileMenuItemProps) {
  return (
    <MenuItem
      className={cn('dropdown-menu-item', className, {
        'dropdown-menu-item--half-padding': halfPadding,
      })}
      contentClassName={contentClassName}
      noHover={noHover}
      leftIconName={leftIconName}
      leftIconPrefix={leftIconPrefix}
      onClick={onClick}
      autoFocus={autoFocus}
      to={to}
      divider={divider}
      replace={replace}
      badge={badge}
    >
      <span className={cn('dropdown-menu-item__text')}>{children}</span>
    </MenuItem>
  );
}
