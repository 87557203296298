import * as React from 'react';

import LinkMaterial from '@mui/material/Link';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';

import { ColorEnum } from '@components/Link/constants';

import { LinkProps } from './types';
import { isExternalUrl } from './utils';

import styles from './Link.module.css';

const cn = classNames.bind(styles);

export function Link({
  children,
  className,
  target,
  rel,
  to,
  disabled,
  onClick,
  ariaLabelledBy,
  tabIndex,
  ariaLabel,
  role,
  state,
  focused,
  // style props
  color = ColorEnum.PURPLE,
  noUnderline = false,
  reverseUnderline = false,
  bold = false,
}: LinkProps) {
  const isExternal = isExternalUrl(to);
  const commonClassName = cn('link', className, {
    [`link--${color}`]: color,
    [`link--no-underline`]: noUnderline,
    [`link--reverse-underline`]: reverseUnderline,
    'link--bold': bold,
    'link--focused': focused,
  });

  const commonProps = {
    rel,
    onClick,
    tabIndex,
    role,
    className: commonClassName,
    color: 'inherit',
    'aria-labelledby': ariaLabelledBy,
    'aria-label': ariaLabel,
  };

  return isExternal ? (
    <LinkMaterial {...commonProps} component="a" target={target ?? '_blank'} href={disabled ? '' : to}>
      {children}
    </LinkMaterial>
  ) : (
    <LinkMaterial {...commonProps} component={RouterLink} target={target} to={disabled ? '' : to} state={state}>
      {children}
    </LinkMaterial>
  );
}
