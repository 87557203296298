import React from 'react';

import classNames from 'classnames/bind';

import { getLocale } from '@common/utils/locale';
import { FaIcon } from '@components/FaIcon';
import { ColorEnum, LinkButton } from '@components/Link';
import { Typography } from '@components/Typography';

import { locale } from './locale';

import styles from './AttachmentInfo.module.css';

const cn = classNames.bind(styles);

export type AttachmentInfoProps = {
  attachmentsFormats: string[];
  handleOpenPrivacyPolicy: VoidFunction;
};

export function AttachmentInfo({ attachmentsFormats, handleOpenPrivacyPolicy }: AttachmentInfoProps) {
  return (
    <div className={cn('attachments-info-container')}>
      <FaIcon className={cn('attachments-info-icon')} iconName="info-circle" size="small"/>
      <div>
        <Typography variant="body2">
          {locale.only_attaches}
          <LinkButton color={ColorEnum.GRAY_20} onClick={handleOpenPrivacyPolicy}>
            {locale.privacy_policy}
          </LinkButton>
          {locale.only_attaches2}
        </Typography>
        <Typography variant="body2" id="attachment_requirements">
          {getLocale(locale.formats, {
            attachmentsFormats: attachmentsFormats.map((format) => format.toUpperCase()).join(', '),
          })}
        </Typography>
      </div>
    </div>
  );
}
