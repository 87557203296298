import React, { ChangeEvent, KeyboardEventHandler, useCallback } from 'react';

import CheckboxMaterial, { CheckboxProps as CheckboxPropsMaterial } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, {
  FormControlLabelProps as FormControlLabelPropsMaterial,
} from '@mui/material/FormControlLabel';
import classNames from 'classnames/bind';

import { FaIcon } from '@components/FaIcon';
import { FormHelperText } from '@components/FormHelperText';

import styles from './Checkbox.module.css';

const cn = classNames.bind(styles);

export type CheckboxProps = {
  className?: string;
  checkBoxClassName?: string;
  checkBoxIconClassName?: string;
  indeterminate?: boolean;
  labelClassName?: string;
  label?: FormControlLabelPropsMaterial['label'];
  variant?: 'outlined' | 'standard';
  helperText?: string;
  labelPlacement?: 'end' | 'start';
  checked?: boolean;
  error?: boolean;
  size?: 'medium' | 'small';
} & Pick<CheckboxPropsMaterial, 'onChange' | 'required' | 'disabled' | 'value' | 'name' | 'focusVisibleClassName'>;

export function Checkbox({
  className,
  checkBoxClassName,
  indeterminate,
  checkBoxIconClassName,
  labelClassName,
  disabled = false,
  label,
  name = '',
  onChange,
  required = false,
  value = false,
  labelPlacement,
  variant = 'standard',
  checked = !!value,
  helperText = '',
  error = false,
  focusVisibleClassName,
  size = 'medium',
}: CheckboxProps) {
  const handleKeyDown = useCallback<KeyboardEventHandler>(
    (event) => {
      if (event.code === 'Enter') {
        onChange?.(event as unknown as ChangeEvent<HTMLInputElement>, !checked);
      }
    },
    [onChange, checked],
  );

  const checkbox = (
    <CheckboxMaterial
      disabled={disabled}
      required={required}
      className={cn('checkbox__input', checkBoxClassName, {
        'checkbox__input--disabled': disabled,
      })}
      inputProps={{
        className: cn('checkbox__input'),
      }}
      focusVisibleClassName={cn('checkbox__focus-visible', focusVisibleClassName)}
      checked={checked}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      name={name}
      indeterminate={indeterminate}
      size={size}
      icon={
        <FaIcon className={cn('checkbox__unchecked-icon', checkBoxIconClassName)} iconName="square" iconPrefix="far" />
      }
      checkedIcon={<FaIcon className={cn('checkbox__checked-icon')} iconName="square-check" iconPrefix="fas" />}
      indeterminateIcon={
        <FaIcon
          className={cn('checkbox__checked-icon', checkBoxIconClassName)}
          iconName="square-minus"
          iconPrefix="fas"
        />
      }
      disableRipple
    />
  );

  return (
    <FormControl variant={variant} required={required} className={cn('checkbox', className)}>
      <FormControlLabel
        disabled={disabled}
        control={checkbox}
        labelPlacement={labelPlacement}
        className={cn('checkbox__label', labelClassName, {
          'checkbox__label--disabled': disabled,
        })}
        label={label}
      />

      {!!helperText && <FormHelperText error={error} helperText={helperText} className={cn('checkbox__text')} />}
    </FormControl>
  );
}
