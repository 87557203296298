import React, { useMemo, useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '@common/utils';
import { getLocale } from '@common/utils/locale';
import { FaIcon } from '@components/FaIcon';
import { IconButton } from '@components/IconButton';
import { Pagination } from '@components/Pagination';
import { Typography } from '@components/Typography';

import { FitnessTypes, WellnessTypesEnum, ITEMS_ON_PAGE } from '../../constants';
import { setPage, getCurrentPage } from '../../store';
import { ExerciseItemType } from '../../types';
import { getItemsByPage } from '../../utils';
import { HistoryListSkeleton } from './components/HistoryListSkeleton';
import { locale } from './locale';

import styles from './HistoryList.module.css';

const cn = classNames.bind(styles);

type HistoryListProps = {
  items: ExerciseItemType[];
  title: string;
  loading: boolean;
  onEditItemClick: (item: ExerciseItemType) => unknown;
  onDeleteItemClick: (item: ExerciseItemType) => unknown;
  type: WellnessTypesEnum;
  isTablet: boolean;
};

const getHeader = (name?: string, value?: string, separator = ' ') => [name, value].filter(Boolean).join(separator);

export function HistoryList({
  items,
  title,
  loading,
  onEditItemClick,
  onDeleteItemClick,
  type,
  isTablet,
}: HistoryListProps) {
  const currentPage = useSelector(getCurrentPage(type));
  const dispatch = useDispatch();

  const handleChangePage = useCallback(
    (_: unknown, page: number) => {
      dispatch(setPage({ page, type }));
    },
    [type, dispatch],
  );

  const nextItems = useMemo(() => getItemsByPage(currentPage, items), [items, currentPage]);

  if (loading) {
    return <HistoryListSkeleton />;
  }

  return (
    <div className={cn('history-list')}>
      <Typography variant="h5" className={cn('history-list__title')} component={!isTablet ? 'h4' : 'h3'}>
        {title}
      </Typography>

      {!items.length && <div className={cn('history-list__no-entries')}>{locale.no_entries}</div>}

      {nextItems.map((item) => {
        const value = [item.result_label, item.result_label_distance].filter(Boolean).join(' / ');
        const isOther = item.exercise_type?.type === FitnessTypes.OtherFitnessActivity;
        const name = isOther ? `${locale.other}: ${item.name}` : item.exercise_type?.label;
        const header = isOther ? getHeader(name, value) : getHeader(name, value, ': ');

        return (
          <div key={item.id} className={cn('history-list__item')}>
            <div>
              <Typography variant="h5" className={cn('history-list__item-header')} component={!isTablet ? 'h5' : 'h4'}>
                {header}
              </Typography>
              <Typography variant="body2" className={cn('history-list__item-sub-header')}>
                Recorded on {formatDate(item.recorded_at, 'MM/dd/yy')} at {formatDate(item.recorded_at, 'hh:mm a')} by{' '}
                <span className={cn('history-list__source')}>{item.source}</span>
              </Typography>
            </div>

            {item.can_edit_or_delete && (
              <div className={cn('history-list__icons')}>
                <IconButton
                  className={cn('history-list__button')}
                  onClick={() => onEditItemClick(item)}
                  aria-label={getLocale(locale.edit, { entry: header })}
                >
                  <FaIcon iconName="pen" className={cn('history-list__icon')} size="small" />
                </IconButton>
                <IconButton
                  className={cn('history-list__button')}
                  onClick={() => onDeleteItemClick(item)}
                  aria-label={getLocale(locale.delete, { entry: header })}
                >
                  <FaIcon iconName="trash-can" className={cn('history-list__icon')} size="small" />
                </IconButton>
              </div>
            )}
          </div>
        );
      })}

      {items.length > ITEMS_ON_PAGE && (
        <div className={cn('history-list__pagination')}>
          <Pagination
            onChange={handleChangePage}
            page={currentPage}
            count={Math.ceil(items.length / ITEMS_ON_PAGE)}
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </div>
  );
}
