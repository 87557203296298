import React, { SyntheticEvent, useMemo } from 'react';

import classNames from 'classnames/bind';

import { formatDate } from '@common/utils/dateTimeUtil';
import { getLocale } from '@common/utils/locale';
import { Button } from '@components/Button';
import { Card } from '@components/Card';
import { Typography } from '@components/Typography';

import { ExerciseItemType } from '../../types';
import { locale } from './locale';

import styles from './WellnessCard.module.css';

const cn = classNames.bind(styles);

type WellnessCardProps = {
  title: string;
  className?: string;
  selected?: boolean;
  buttonLabel: string;
  mostRecentEntry: ExerciseItemType;
  onCardClick?: (event: SyntheticEvent) => void;
  onButtonClick?: (event: SyntheticEvent) => void;
};

export function WellnessCard({
  title,
  selected,
  className,
  mostRecentEntry,
  buttonLabel,
  onCardClick,
  onButtonClick,
}: WellnessCardProps) {
  const handleButtonClick = (event: SyntheticEvent) => {
    event.stopPropagation();

    onButtonClick?.(event);
  };

  const handleCardClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onCardClick?.(event);
  };

  const value = useMemo(() => {
    const label = mostRecentEntry.result_label || mostRecentEntry.result_label_distance || '';
    const nameLabel = mostRecentEntry.name ? `${mostRecentEntry.name} ${label}` : label;

    if (mostRecentEntry?.exercise_type?.label) {
      return `${mostRecentEntry?.exercise_type?.label}: ${nameLabel}`;
    }

    return nameLabel;
  }, [mostRecentEntry]);

  return (
    <div className={cn('wellness-card-wrapper', className)}>
      <Card
        role="button"
        className={cn('wellness-card', className, {
          'wellness-card--selected': selected,
        })}
        onClick={handleCardClick}
        onKeyPress={handleCardClick}
        tabIndex={0}
        ariaLabel={getLocale(locale.wellness_card_aria, { title, entry: value })}
      >
        <div className={cn('wellness-card__header')}>
          <Typography variant="h2" className={cn('wellness-log__title')}>
            {title}
          </Typography>
        </div>

        <div
          className={cn('wellness-card__most-recent-entry', { 'wellness-card__most-recent-entry--selected': selected })}
        >
          <div className={cn('wellness-card__most-recent-entry-title')}>{locale.most_recent_entry}</div>

          {value && (
            <Typography variant="h3" className={cn('wellness-card__most-recent-entry-value')}>
              {value}
            </Typography>
          )}

          {mostRecentEntry.recorded_at && (
            <div className={cn('wellness-card__most-recent-entry-date')}>
              Recorded on {formatDate(mostRecentEntry.recorded_at, 'MM/dd/yy')} by{' '}
              <span className={cn('wellness-card__source')}>{mostRecentEntry.source}</span>
            </div>
          )}
        </div>
      </Card>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        startIcon="pen"
        className={cn('wellness-card__activity-button')}
        iconClass={cn('wellness-card__activity-button--icon')}
        onClick={handleButtonClick}
      >
        {buttonLabel}
      </Button>
    </div>
  );
}
