export enum COLORS {
  GRAY_0 = '#6c737a',
  GRAY_20 = '#3e4246',
  GRAY_25 = '#323639',
  ORANGE_0 = '#db2c00',
  WHITE = '#ffffff',
  PURPLE_10 = '#963695',
  MINT_15 = '#1a8262',
  ORANGE_20 = '#db2c00',
  BLUE_0 = '#1c738f',
  PURPLE_0 = '#642667',
  DEEP_BLUE_0 = '#003A51',
  PRIMARY_GREEN_40 = '#066E4B',
  PRIMARY_GREEN_0 = '#00A665',
  TEAL_20 = '#007077',
  SECONDARY_GREEN_0 = '#095540',
}
