import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { logoutStart } from '@modules/Login';

import { ActionItemInner } from '../../components/ActionItemInner';
import { MenuItemType } from '../../types';
import { locale } from './locale';

export const useLogoutMenuItem = (): MenuItemType => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logoutStart({ inactivityLogout: false }));
  }, [dispatch]);

  return useMemo(
    () => ({
      action: handleLogout,
      component: <ActionItemInner title={locale.logout} faIconName="arrow-right-from-bracket" />,
    }),
    [handleLogout],
  );
};
