import { COLORS } from '@common/constants/colors';

export enum EligibilityTypesEnum {
  Employee = 'Employee',
  Contractor = 'Contractor',
  Retiree = 'Retiree',
  COBRA = 'COBRA',
  OnLeave = 'On Leave',
  Spouse = 'Spouse',
  SameSexPartner = 'Same Sex Partner',
  DomesticPartner = 'Domestic Partner',
  EmployeeSpouse = 'Employee/Spouse',
  RetireeSpouse = 'Retiree/Spouse',
  Child = 'Child',
  Dependent = 'Dependent',
}

export const ALLOWED_ELIGIBILITIES_FOR_FAMILY_MEMBERS = [
  EligibilityTypesEnum.Employee,
  EligibilityTypesEnum.OnLeave,
  EligibilityTypesEnum.COBRA,
  EligibilityTypesEnum.Retiree,
  EligibilityTypesEnum.Contractor,
];

export const ALLOWED_TO_INVITE_ELIGIBILITIES = [
  EligibilityTypesEnum.Employee,
  EligibilityTypesEnum.Contractor,
  EligibilityTypesEnum.Retiree,
  EligibilityTypesEnum.COBRA,
  EligibilityTypesEnum.OnLeave,
  EligibilityTypesEnum.Spouse,
  EligibilityTypesEnum.SameSexPartner,
  EligibilityTypesEnum.DomesticPartner,
  EligibilityTypesEnum.EmployeeSpouse,
  EligibilityTypesEnum.RetireeSpouse,
];

export const EMPTY_GENDER_ID = '--';

export const FAMILY_MEMBERS_COLORS = {
  purple: COLORS.PURPLE_0,
  blue: COLORS.DEEP_BLUE_0,
  green: COLORS.PRIMARY_GREEN_40,
  teal: COLORS.TEAL_20,
  secondaryGreen: COLORS.SECONDARY_GREEN_0,
} as const;

export const FAMILY_MEMBERS_COLORS_ARRAY = Object.values(FAMILY_MEMBERS_COLORS);

export enum FamilyMemberRegisterStatusEnum {
  Uneligible = 0,
  Registered = 1,
  Unregistered = 2,
}
