import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import throttle from 'lodash.throttle';

import { FaIcon } from '@components/FaIcon';

import { locale } from './locale';

import styles from './ScrollToTop.module.css';

const cn = classNames.bind(styles);

export const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);
  const handleGoToTop = useCallback(() => {
    document.body.tabIndex = -1;
    document.body.focus();
    document.body.removeAttribute('tabIndex');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollHandler = useCallback(
    throttle(() => {
      if (document.documentElement.scrollTop > 500 && !visible) {
        setVisible(true);
      }

      if (document.documentElement.scrollTop < 500 && visible) {
        setVisible(false);
      }
    }, 200),
    [visible],
  );

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  if (!visible) {
    return null;
  }

  return (
    <div
      className={cn('root')}
      onClick={handleGoToTop}
      onKeyPress={handleGoToTop}
      role="button"
      tabIndex={0}
      data-testid="scroll_to_top"
      title={locale.scroll_to_top}
      aria-label={locale.scroll_to_top}
    >
      <FaIcon iconName="arrow-up" className={cn('icon')} />
    </div>
  );
};
